<template>
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item-sites :abbr="false" :multiple="false" :local="true" v-model="queryForm.channel" :parent="false" @onload="q.channel=queryForm.channel;searchEvent()" :clearable="false"></form-item-sites>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">搜 索</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="exportData">下 载</el-button>
					</form-item>
					<form-item>
						<upload-button action="/adms_api_v0/product/import" accept=".csv" title="上传选款" tooltip="csv文件,表头为'website,spu,category'"></upload-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="transitionToPage('fb-ads_carousel_list')">返 回</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item-categories v-model="queryForm.category" :site="queryForm.channel"></form-item-categories>
					<form-item label="选款规则:" style="width:230px;">
						<el-select v-model="queryForm.rule" placeholder="请选择" size="mini" clearable>
							<el-option v-for="item in recommendRules" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</form-item>
					<form-item label="商品id:" style="width:200px;">
						<el-input v-model="queryForm.spu" placeholder="请输入" size="mini" clearable></el-input>
					</form-item>
					<form-item label="选择日期:" style="width:400px;">
						<el-date-picker v-model="queryForm.date" type="datetimerange" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="timestamp" style="width:340px;" size="mini" :clearable="false">
						</el-date-picker>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
				<div class="d-flex flex-row align-items-center">
					<el-button type="primary" size="mini" @click="viewBlock">查看屏蔽选款</el-button>
					<el-button type="primary" size="mini" @click="doPreviewAds('config')" class="mr-2">预览广告</el-button>
					<upload-button action="/adms_api_v0/product/images/upload" accept=".zip" title="上传UI作图" tooltip="zip文件" class="mr-2"></upload-button>
					<el-tooltip content="请选择1～9商品" placement="bottom">
						<el-button class="mr-2" type="primary" size="mini" effect="light" @click="exportToSingle">生成单图搭配</el-button>
					</el-tooltip>
					<el-checkbox v-model="blockingRule" class="mb-0">去除屏蔽规则</el-checkbox>
				</div>
				<alert v-if="multipleSelection.length > 0" class="my-2">
					已选择<span class="text-primary d-inline-block mx-1">{{multipleSelection.length}}</span>个商品
				</alert>
				<distincted-table ref="distincted-table" :tableData="tableData" @handleSelectionChange="handleSelectionChange" class="mt-3 w-100"></distincted-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
			<ads-config-dialog :visible.sync="visible.adsConfig" @callback="(form)=>doPreviewAds('preview', form)">
			</ads-config-dialog>
			<ads-preview-dialog ref="preview-dialog" :visible.sync="visible.preview" :data="previewData" @callback="()=>doPreviewAds('publish')">
			</ads-preview-dialog>
			<distinct-products-dialog :visible.sync="visible.distinct" :channel="q.channel" :blockingRule="blockingRule" :namespace="namespace"></distinct-products-dialog>
		</el-card>
</template>

<script>
	import Moment from 'moment'
	import Page from '@/common/mixins/page.js'
	import Common from '@/common/mixins/common.js'
	import Dialogs from '@/components/dialog'
	import DistinctedTable from '@/components/carousels/distincted-table.vue'
	import Config from './mixins/config.js'
	import {
		mapGetters
	} from 'vuex'
	export default {
		mixins: [Page, Common, Config],
		inject: ['adminLayout'],
		components: {
			...Dialogs,
			DistinctedTable
		},
		data() {
			return {
				blockingRule: false,
				visible: {
					adsConfig: false,
					preview: false,
					distinct: false
				},
				previewData: {}
			}
		},
		computed: {
			...mapGetters({
				recommendRules: `fb-carousel-ads/recommendRules`
			})
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.page,
						...this.q,
						orderBy: this.orderBy
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getDistinctedList`, payload)
			},
			// Button Events
			doPreviewAds(type = 'config', data = null) {
				if (this.multipleSelection.length === 0) {
					return this.$showErrMsg('请先选择商品')
				}
				switch (type) {
					case 'config':
						this.visible.adsConfig = true
						break
					case 'preview':
						this.visible.adsConfig = false
						this.adminLayout.showLoading()
						const payload = {
							body: {
								channel: this.q.channel,
								...data,
								productIds: this.multipleSelection.map(v => {
									return {
										id: v.id,
										originPic: v.originPic
									}
								})
							}
						}
						this.$store.dispatch(`${this.namespace}/preview`, payload)
							.then(res => {
								this.previewData = res
								const {publishType} = data
								if (publishType === 'Normal' || publishType === 'NEW_TEST') {
									this.$set(this.previewData, 'startAt', null)
									this.$set(this.previewData, 'attribution', this.q.channel === 'HKP' ? '1D_CLICK' : '1D_CLICK_OR_VIEW')
								}
								this.$set(this.previewData, 'status', 'PAUSED')
								this.adminLayout.hideLoading()
								this.visible.preview = true
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
						break
					case 'publish':
						const {startAt} = this.previewData
						if (!!startAt && startAt - new Date().getTime() < 1800*1000) {
							this.$showErrMsg('开始时间需大于当前时间30min+')
							return
						}
						this.adminLayout.showLoading(true)
						this.$store.dispatch(`${this.namespace}/publish`, {id: this.previewData.id, body: this.$refs['preview-dialog'].getOptionsBody()})
							.then(res => {
								this.$refs['distincted-table'].$refs.multipleTable.clearSelection()
								this.visible.preview = false
								this.adminLayout.hideLoading()
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
					default:
						break
				}
			},
			viewBlock() {
				this.visible.distinct = true
			},
			exportToSingle() {
				if (this.multipleSelection.length < 1) {
					return
				}
				if (this.multipleSelection.length > 9) {
					return this.$showErrMsg('单图商品最大只支持9张图片')
				}
				const ids = this.multipleSelection.map(v => v.id)
				this.adminLayout.showLoading()
				this.$store.dispatch('fb-carousel-ads/newSingleItem', ids)
				.then(res => {
					this.$showSuccess('转换成功')
					this.adminLayout.hideLoading()
					this.$refs['distincted-table'].$refs.multipleTable.clearSelection()
				})
				.catch(err => {
					this.adminLayout.hideLoading()
				})
			},
			exportData() {
				if (JSON.stringify(this.q) === '{}') return
				const createdAtMin = Moment(Number(this.queryForm.date[0])).format('YYYY-MM-DD HH:mm:ss')
				const createdAtMax = Moment(Number(this.queryForm.date[1])).format('YYYY-MM-DD HH:mm:ss')
				this.$store.commit('download-manager/addTask', {
					action: `${this.namespace}/getDistinctedList`,
					payload: {...this.q, orderBy: this.orderBy},
					title: `Distinct List(${createdAtMin}~${createdAtMax})`,
					pagable: {page: 1, size: 50},
					dispatch: this.$store.dispatch,
					requestHandler() {
						const params = Array.from(arguments).reduce((lhs, rhs) => {
							return {...lhs, ...rhs}
						}, {})
						return {params}
					}
				})
			}
		}
	}
</script>
